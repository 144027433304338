import React, { useEffect } from "react";

const PageNotFound = () => {
  useEffect(() => {
    window.location.pathname = "/";
  }, []);
  return <>Page not found. You are being redirected.</>;
};

export default PageNotFound;
